@import '../../../node_modules/tachyons-sass/scss/skins';


// Converted Variables


// Custom Media Query Variables


/*

   SKINS
   Docs: http://tachyons.io/docs/themes/skins/

   Classes for setting foreground and background colors on elements.
   If you haven't declared a border color, but set border on an element, it will
   be set to the current text color.

*/

@mixin create-skin($prefix, $color: null, $var: null) {
  .#{$prefix}#{$color},
  .hover-#{$prefix}#{$color}:hover,
  .hover-#{$prefix}#{$color}:focus { color: $var; }
  .bg-#{$prefix}#{$color},
  .hover-bg-#{$prefix}#{$color}:hover,
  .hover-bg-#{$prefix}#{$color}:focus { background-color: $var; }
  .b--#{$prefix}#{$color} { border-color: $var; }
}

/* The Message */
@include create-skin(mssg-, black, $near-black);
@include create-skin(mssg-, white, $mssg-white);
@include create-skin(mssg-, yellow, $mssg-yellow);
@include create-skin(mssg-, orange, $mssg-orange);

/* Chelsea Hotel #1 */
@include create-skin(chls-, black, $chls-black);
@include create-skin(chls-, white, $chls-white);
@include create-skin(chls-, blue, $chls-blue);
@include create-skin(chls-, red, $chls-red);

/* Beds */
@include create-skin(beds-, black, $beds-black);
@include create-skin(beds-, white, $beds-white);
@include create-skin(beds-, purple, $beds-purple);

/* Flesh & Blood */
@include create-skin(fab-, black, $fab-black);
@include create-skin(fab-, white, $fab-white);
@include create-skin(fab-, red, $fab-red);
@include create-skin(fab-, yellow, $fab-yellow);

/* Grenade */
@include create-skin(grnd-, black, $grnd-black);
@include create-skin(grnd-, gray, $grnd-gray);
@include create-skin(grnd-, white, $grnd-white);
@include create-skin(grnd-, gold, $grnd-gold);

/* Verso */
@include create-skin(vrso-, black, $vrso-black);
@include create-skin(vrso-, pink, $vrso-pink);
@include create-skin(vrso-, white, $vrso-white);
@include create-skin(vrso-, snuff, $vrso-snuff);

/* Nostalgia */
@include create-skin(nost-, gray, $nost-gray);
@include create-skin(nost-, white, $nost-white);
@include create-skin(nost-, pink, $nost-pink);

/* Sepia Spectres */
@include create-skin(sepia-, black, $sepia-black);
@include create-skin(sepia-, gray, $sepia-gray);
@include create-skin(sepia-, white, $sepia-white);
@include create-skin(sepia-, orange, $sepia-orange);

/* Theme */
@include create-skin(primary, null, $primary);
@include create-skin(secondary, null, $secondary);

@include create-skin(true-black, null, #000000);

/* Custom responsive colours */
@media #{$breakpoint-not-small} {
  .near-white-ns {
    color: $near-white;
  }
}
