
// Converted Variables


// Custom Media Query Variables


/*

   MAX WIDTHS

   Base:
     mh = max-height

   Modifiers
     1 = 1st step in height scale
     2 = 2nd step in height scale
     3 = 3rd step in height scale
     4 = 4th step in height scale
     5 = 5th step in height scale
     6 = 6st step in height scale
     7 = 7nd step in height scale
     8 = 8rd step in height scale
     9 = 9th step in height scale

     -none  = string value none


   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


/* Max Height Scale */

.mh1  {  max-height: $max-height-1; }
.mh2  {  max-height: $max-height-2; }
.mh3  {  max-height: $max-height-3; }
.mh4  {  max-height: $max-height-4; }
.mh5  {  max-height: $max-height-5; }
.mh6  {  max-height: $max-height-6; }
.mh7  {  max-height: $max-height-7; }
.mh8  {  max-height: $max-height-8; }
.mh9  {  max-height: $max-height-9; }

/* Max Width String Properties */

.mh-none { max-height: none; }

@media #{$breakpoint-not-small} {
  .mh1-ns  {  max-height: $max-height-1; }
  .mh2-ns  {  max-height: $max-height-2; }
  .mh3-ns  {  max-height: $max-height-3; }
  .mh4-ns  {  max-height: $max-height-4; }
  .mh5-ns  {  max-height: $max-height-5; }
  .mh6-ns  {  max-height: $max-height-6; }
  .mh7-ns  {  max-height: $max-height-7; }
  .mh8-ns  {  max-height: $max-height-8; }
  .mh9-ns  {  max-height: $max-height-9; }

  .mh-none-ns { max-height: none; }
}

@media #{$breakpoint-medium} {
  .mh1-m  {  max-height: $max-height-1; }
  .mh2-m  {  max-height: $max-height-2; }
  .mh3-m  {  max-height: $max-height-3; }
  .mh4-m  {  max-height: $max-height-4; }
  .mh5-m  {  max-height: $max-height-5; }
  .mh6-m  {  max-height: $max-height-6; }
  .mh7-m  {  max-height: $max-height-7; }
  .mh8-m  {  max-height: $max-height-8; }
  .mh9-m  {  max-height: $max-height-9; }

  .mh-none-m { max-height: none; }
}

@media #{$breakpoint-large} {
  .mh1-l  {  max-height: $max-height-1; }
  .mh2-l  {  max-height: $max-height-2; }
  .mh3-l  {  max-height: $max-height-3; }
  .mh4-l  {  max-height: $max-height-4; }
  .mh5-l  {  max-height: $max-height-5; }
  .mh6-l  {  max-height: $max-height-6; }
  .mh7-l  {  max-height: $max-height-7; }
  .mh8-l  {  max-height: $max-height-8; }
  .mh9-l  {  max-height: $max-height-9; }

  .mh-none-l { max-height: none; }
}
