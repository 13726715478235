@import '../../../node_modules/tachyons-sass/scss/styles';

// TODO: toggle color class with JS
#navTop .active {
  &,
  > a:hover,
  > a:focus {
    color: $primary;
  }
}
.inverse #navTop {
  a {
    color: $near-black;
  }
  .active a,
  a:hover,
  a:focus {
    color: $primary;
  }
}

// Video lightbox
.video-lightbox {
  .mw7-custom {
    max-width: $max-width-7 + 8;
  }
  .mw8-custom {
    max-width: $max-width-8 + 8;
  }
}

// Lockups
.amazon-music-lockup {
  max-height: 2.5rem;
  max-width: 4.375rem;
}
.apple-music-preadd-lockup {
  max-height: 2.5rem;
  max-width: 11.5rem;
}
.apple-music-lockup {
  max-height: 2.5rem;
  max-width: 11.5rem;
}
.deezer-lockup {
  max-height: 2.5rem;
  max-width: 9.5rem;
}
.google-play-lockup {
  max-height: 2.5rem;
  max-width: 9.5rem;
}
.tidal-horizontal-lockup {
  max-height: 2.5rem;
  max-width: 10rem;
}
.spotify-presave-lockup {
  max-height: 2.5rem;
  max-width: 8.5rem;
}
.spotify-lockup {
  max-height: 2.5rem;
  max-width: 8.25rem;
}
