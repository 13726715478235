@import '../../../node_modules/tachyons-sass/scss/utilities';

// Converted Variables


// Custom Media Query Variables


/*

   UTILITIES

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


// Tap highlight on iOS
.disable-highlight { -webkit-tap-highlight-color: rgba(0,0,0,0); }

// User text selection
.disable-select {
  user-select: none;
  -webkit-touch-callout: none; // Disable actions panel
  @extend .disable-highlight;
}
.enable-select { user-select: auto; }

// Auto vertical margins
.mv-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mt-auto { margin-top: auto; }
.mb-auto { margin-bottom:  auto; }

@media #{$breakpoint-not-small}{
  .mv-auto-ns {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-auto-ns { margin-top: auto; }
  .mb-auto-ns { margin-bottom:  auto; }
}

@media #{$breakpoint-medium}{
  .mv-auto-m {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-auto-m { margin-top: auto; }
  .mb-auto-m { margin-bottom:  auto; }
}

@media #{$breakpoint-large}{
  .mv-auto-l {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-auto-l { margin-top: auto; }
  .mb-auto-l { margin-bottom:  auto; }
}
