@import '../../../node_modules/tachyons-sass/scss/coordinates';

// Converted Variables


// Custom Media Query Variables


/*

   COORDINATES
   Docs: http://tachyons.io/docs/layout/position/

   Use in combination with the position module.

   Base:
     top
     left

   Modifiers:
     50%  = literal value 50%

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.top-50    { top:    50%; }
.left-50   { left:   50%; }

@media #{$breakpoint-not-small} {
  .top-50-ns     { top:   50%; }
  .left-50-ns    { left:  50%; }
}

@media #{$breakpoint-medium} {
  .top-50-m     { top:   50%; }
  .left-50-m    { left:  50%; }
}

@media #{$breakpoint-large} {
  .top-50-l     { top:   50%; }
  .left-50-l    { left:  50%; }
}
