@import '../../../node_modules/tachyons-sass/scss/spacing';

// Converted Variables


// Custom Media Query Variables


/* Variables */

/*
   SPACING

   Safe area inset for iOS 11 and new iPhone X "notch"

   Base:
     p = padding

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     safe = safe-area-inset

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.pa-safe { padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left); }

.pl-safe { padding-left: constant(safe-area-inset-left); }
.pr-safe { padding-right: constant(safe-area-inset-right); }
.pb-safe { padding-bottom: constant(safe-area-inset-bottom); }
.pt-safe { padding-top: constant(safe-area-inset-top); }
