@import '../../node_modules/tachyons-sass/scss/visibility';

// Converted Variables


// Custom Media Query Variables


/*

   VISIBILITY

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/


.hidden {  visibility: hidden; }
.visible { visibility: visible; }

@media #{$breakpoint-not-small} {
  .hidden-ns {  visibility: hidden; }
  .visible-ns { visibility: visible; }
}

@media #{$breakpoint-medium} {
  .hidden-m {  visibility: hidden; }
  .visible-m { visibility: visible; }
}

@media #{$breakpoint-large} {
  .hidden-l {  visibility: hidden; }
  .visible-l { visibility: visible; }
}
