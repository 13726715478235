@import '../../../node_modules/tachyons-sass/scss/typography';

// Converted Variables


// Custom Media Query Variables


/*

   TYPOGRAPHY
   http://tachyons.io/docs/typography/measure/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

blockquote cite {
  &:before {
    content: "\2014";
    margin-right: .3em;
  }
}
