
// Converted Variables


// Custom Media Query Variables


/*

    BACKGROUND CLIP

    Base:
    bg-clip = background clip

    Modifiers:
    -border = border-box
    -padding = padding-box
    -content = content-box

 */

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}
