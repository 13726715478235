// Variables defined before Tachyons variables because
// the !default flag means the value is a fallback if
// previously undefined

// Asset paths
$img-path: '../img/';
$dev-img-path: 'site/static/img/';
$font-path: '../fonts/';

// Song palettes
// The Message
$mssg-black: #2B2D2F;
$mssg-white: #F9ECD1;
$mssg-orange: #D59A6B;
$mssg-yellow: mix($mssg-white, $mssg-black, 60%);
$mssg-green: #b8deb2; // #dae8ba;
// Chelsea Hotel #1
$chls-black: #303239;
$chls-white: #F1F6FE;
$chls-blue: #BBC9E1;
$chls-red: #D46769;
// Beds
$beds-black: #232326;
$beds-white: #E3E0EC;
$beds-purple: #9A8CCE;
// Flesh & Blood
$fab-black: #15171D;
$fab-white: #F5F0DF;
$fab-red: #BB4B48;
$fab-yellow: #D1D18B;
// Grenade
$grnd-black: #141514;
$grnd-gray: #1f1f1f;
$grnd-white: #FFEAD8;
$grnd-gold: #C79E5E;
// Verso
$vrso-black: #1B1B1B;
$vrso-pink: #EC8489;
$vrso-white: #FFFBFF;
$vrso-snuff: #F5E9F6;
// Sepia Spectres
$sepia-black: #121015;
$sepia-gray: #27252A;
$sepia-white: #E3D9C7;
$sepia-orange: #CE7B63;
// Nostalgia
$nost-gray: #1D1C1F;
$nost-white: #D4CFC5;
$nost-pink: #BC8696;

// Theme colors
$near-black: $sepia-gray;
$near-white: $sepia-white;
$black: $sepia-black; // darken($near-black, 5%);
$white: lighten($sepia-white, 5%);
$dark-gray: desaturate(darken($white, 70%), 90%);
$mid-gray: desaturate(darken($white, 60%), 90%);
$gray: desaturate(darken($white, 50%), 90%);
$silver: desaturate(darken($white, 40%), 90%);
$light-silver: desaturate(darken($white, 30%), 90%);
$moon-gray: desaturate(darken($white, 20%), 90%);
$light-gray: desaturate(darken($white, 10%), 90%);

$black-90: rgba($black, .9);
$black-80: rgba($black, .8);
$black-70: rgba($black, .7);
$black-60: rgba($black, .6);
$black-50: rgba($black, .5);
$black-40: rgba($black, .4);
$black-30: rgba($black, .3);
$black-20: rgba($black, .2);
$black-10: rgba($black, .1);
$black-05: rgba($black, .05);
$black-025: rgba($black, .025);
$black-0125: rgba($black, .0125);
$white-90: rgba($white,.9);
$white-80: rgba($white,.8);
$white-70: rgba($white,.7);
$white-60: rgba($white,.6);
$white-50: rgba($white,.5);
$white-40: rgba($white,.4);
$white-30: rgba($white,.3);
$white-20: rgba($white,.2);
$white-10: rgba($white,.1);
$white-05: rgba($white,.05);
$white-025: rgba($white,.025);
$white-0125: rgba($white,.0125);

$primary: $sepia-orange;
$secondary: lighten($sepia-orange, 10%);

$light-red: saturate(lighten($fab-red, 15%), 20%);


// Base sizes
$font-size-base: 16px; // = 1rem
// Calculate 1px from rem
$px: (1 / strip-units($font-size-base));

// Type scale
$font-size-1: 3.5rem; // ~56px
$font-size-2: 3rem; // ~48px
$font-size-3: 2rem; // ~32px
$font-size-4: 1.5rem; // ~24px
$font-size-5: 1.25rem; // ~20px
$font-size-6: 1rem; // 16px
$font-size-7: .875rem; // ~14px
// Line heights
$line-height-solid: 1.125;
// $line-height-title: 1.25 !default;
$line-height-copy: 1.5;

// Letter spacing
$letter-spacing-tight: -.0125em;
$letter-spacing-3: .75em;
$letter-spacing-4: 4em;

// Measure widths
$measure: (100em / 3); // ~65 characters @ $font-size-4
$measure-narrow: 23em; // ~45 characters @ $font-size-4
$measure-wide: 40em; // ~78 characters @ $font-size-4

// Custom Media Query Variables
$breakpoint-small: 'screen and (max-width: #{36em - $px})'; // 575px
$breakpoint-not-small: 'screen and (min-width: 36em)'; // 576px
$breakpoint-medium: 'screen and (min-width: 36em) and (max-width: #{62em - $px})'; // 576-992px
$breakpoint-large: 'screen and (min-width: 62em)'; // 992px


// Import Tachyons default variables
@import '../../../node_modules/tachyons-sass/scss/variables';

// Max heights
$max-height-1: $max-width-1 !default;
$max-height-2: $max-width-2 !default;
$max-height-3: $max-width-3 !default;
$max-height-4: $max-width-4 !default;
$max-height-5: $max-width-5 !default;
$max-height-6: $max-width-6 !default;
$max-height-7: $max-width-7 !default;
$max-height-8: $max-width-8 !default;
$max-height-9: $max-width-9 !default;

// Fonts
$sans-serif: 'Metropolis', #{$sans-serif}; // Concatenate with original value
$serif: 'Marion', 'Amiri', #{$serif}; // Concatenate with original value

// Box shadows
$box-shadow-5: 0 ($font-size-base * 2) ($font-size-base * 3) $black-20;

// Easing
$ease-out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
