
// Converted Variables

$page-speed:  .4s;
$page-easing: ease-out;


// Custom Media Query Variables


.page {
  // box-shadow: 0px 0px 80px $black-10;
  // transition: box-shadow $page-speed $page-easing;

  &.in {
    // box-shadow: 0px 0px 20px $black-80;
  }

  &.active {
    box-shadow: none;
    transition: none;
  }

  &.out {
    transition: none;
  }
}
