// Long arrow icon
// ------------------------------

$long-arrow-size: 1.8em;
$long-arrow-size-hover: $long-arrow-size * 1.25;
$long-arrow-head-size: .4em;
$long-arrow-stem-size: .1em;

.icon-long-arrow-right {
  border-bottom: $long-arrow-stem-size solid;
  height: 0;
  width: $long-arrow-size;
  transition: width .15s ease-out;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -$long-arrow-head-size/2 + $long-arrow-stem-size/2;
    border-left: $long-arrow-head-size solid;
    border-top: $long-arrow-head-size/2 solid transparent;
    border-bottom: $long-arrow-head-size/2 solid transparent;
  }
  a:hover &,
  a:focus & {
    width: $long-arrow-size-hover;
  }
}

.icon-long-arrow-down {
  border-right: $long-arrow-stem-size solid;
  width: 0;
  height: $long-arrow-size;
  transition: height .15s ease-out;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -$long-arrow-head-size/2 + $long-arrow-stem-size/2;
    border-top: $long-arrow-head-size solid;
    border-left: $long-arrow-head-size/2 solid transparent;
    border-right: $long-arrow-head-size/2 solid transparent;
  }
  a:hover &,
  a:focus & {
    height: $long-arrow-size-hover;
  }
}



// Menu / Back / Close multi-icon
// ------------------------------

.menu-toggle {
  $slower: .7s;
  $fast: .2s;
  $size: 1.875em;
  $height: ((2 / 16) * 1em);
  $width: $size;

  .menu-icon {
    height: $size;
    width: $size;
    transform: translate3d(0,0,0);

    span {
      border-bottom: $height solid;
      width: $width;
      margin-left: $width/2 * -1;
      transition: all $fast ease;
      transition-property: top, transform, width, opacity;

      &:first-child {
        top: $height*3;
        transform-origin: $height*4 $height/2 * -1;
      }
      &:nth-child(2) {
        top: $size/2 - $height/2;
      }
      &:last-child {
        top: $size - $height*4;
        transform-origin: $height*4 $height + ($height/2);
      }
    }
  }

  &:hover,
  &:focus {
    &:not(.close):not(.back) .menu-icon {
      span:first-child { top: $height; }
      span:last-child { top: $size - $height*2; }
    }
  }

  &.close .menu-icon span {
    &:first-child {
      width: $size + $size/3;
      transform: rotate(45deg);
    }
    &:last-child {
      width: $size + $size/3;
      transform: rotate(-45deg);
    }
    &:nth-child(2) { opacity: 0; }
  }

  &.back .menu-icon span {
    &:first-child {
      width: $size/3 * 2;
      transform-origin: $size/3 + $height/2 $height*2;
      transform: rotate(-45deg);
    }
    &:last-child {
      width: $size/3 * 2;
      transform-origin: $size/3 + $height/2 $height * -1;
      transform: rotate(45deg);
    }
    &:nth-child(2) { opacity: 0; }
  }
}

// Menu angle right
.caret {
  transition: transform .2s ease-out;
  width: .75em;
  height: .75em;
  margin-right: .5em;
  margin-left: -1.25em;
}

// Icon check (tick)
.icon--check {
  width: 1em;
  height: 1em;
}
