@import '../../../node_modules/tachyons-sass/scss/skins-pseudo';


// Converted Variables


// Custom Media Query Variables


/*

   SKINS:PSEUDO

   Customize the color of an element when
   it is focused or hovered over.

 */


/* Custom responsive colours */
@media #{$breakpoint-not-small} {
  .hover-secondary-ns {
    &:hover,
    &:focus { color: $white; }
  }
}
