@import '../../../node_modules/tachyons-sass/scss/lists';

// Converted Variables


// Custom Media Query Variables


/*

   LISTS
   http://tachyons.io/docs/elements/lists/

*/

// Fancy lists with better bullets/numbers and spacing
.ul,
.ol {
  li {
    padding-left: 1.5em;
    &:before {
      width: 1.5em;
      margin-left: -1.5em;
      display: inline-block;
      vertical-align: baseline;
    }
  }
}
.ol {
  counter-reset: ol 0;
  li:before {
    counter-increment: ol;
    content: counter(ol) ".";
  }
}
.ul li:before {
  content: "•";
  font-size: 0.8em;
}

.checklist li {
  $size: 1.25em;
  padding-left: 1.5 * $size;
  &:before {
    color: $primary;
    content: "\2713";
    font-size: $size;
    line-height: 1;
  }
}