$font-size-4-org: $font-size-4; // Store original value
$font-size-4: $line-height-title; // Line-height value for tachyons nested headings

@import '../../../node_modules/tachyons-sass/scss/nested';

$font-size-4: $font-size-4-org; // Reset to original value

// Converted Variables


// Custom Media Query Variables


// Dirty temporary way of removing margin from image block grids
.cms-block.mv0,
.cms-block.mt0 {
  &,
  .media-grid {
    margin-top: 0;
  }
}
.cms-block.mv0,
.cms-block.mb0 {
  &,
  .media-grid {
    margin-bottom: 0;
  }
}


/*

    BLOCK TEXT
    Mapping for CMS-generated markdown content

*/

.cms-content {
  word-break: break-word;
  word-wrap: break-word;

  h1 {
    @extend .f1;
    margin-top: 2em;
    + h6 {
      margin-top: .5em;
      @extend .f3;
      @extend .fw4;
    }
  }
  h2 {
    @extend .f2;
    margin-top: 1.75em;
    + h6 { @extend .f3; }
  }
  h3 {
    @extend .f3;
    margin-top: 1.5em;
    + h6 { @extend .f4; }
  }
  h4 {
    @extend .f4;
    margin-top: 1.25em;
    + h6 { @extend .f5; }
  }
  h5 {
    @extend .f5;
    margin-top: 1em;
    + h6 { @extend .f6; }
  }
  h6 {
    @extend .f6;
    @extend .fw4;
    @extend .mssg-yellow;
    @extend .mt1;
    margin-bottom: .8em;
  }
  h1,h2,h3,h4,h5,h6 {
    @extend .sans-serif;
    &:first-child { @extend .mt0; }

    + p,
    + ul,
    + ol { @extend .mt3; }
  }

  p,
  blockquote,
  pre,
  ul,
  ol {
    @extend .mt4;
    @extend .mb0;
    &:first-child { @extend .mt0; }
  }

  a {
    @extend .primary;
    @extend .hover-inherit;
  }

  .project-content & a {
    @extend .color-inherit;
    @extend .hover-primary;
  }

  strong {
    @extend .b;
  }

  blockquote {
    // @extend .athelas;
    @extend .nl3;
    @extend .nl4-ns;
    @extend .bl;
    @extend .bw2;
    @extend .b--primary;
    padding-left: $spacing-medium - .25rem; // (- .bw2 width)
    @media #{$breakpoint-not-small} {
      padding-left: $spacing-large - .25rem; // (- .bw2 width)
    }

    p {
      @extend .i;
      white-space: pre-wrap;
    }

    cite {
      @extend .f7;
      @extend .ttu;
      @extend .tracked;
      @extend .fs-normal;
      @extend .mssg-yellow;
    }
  }

  ul:not(.list-unstyled) { @extend .ul; }
  ol:not(.list-unstyled) { @extend .ol; }
  ul,
  ol {
    @extend .list;
    margin-left: 0;
    padding-left: 0;
    li {
      @extend .mb3;
      &:last-child { @extend .mb0; }
    }
  }

  pre,
  code {
    @extend .bg-black-05;
    @extend .f5;
  }

  p code {
    @extend .pa1;
    @extend .mh1;
  }

  pre {
    white-space: pre-wrap;
    @extend .pa3;

    code { background: none; }

    + pre {
      @extend .mt0;
      @extend .pt1;
    }
  }
}

/*

    SONGKICK
    Mapping for dynamically-generated Songkick tour widget

*/

.start {
  color: red;
}
