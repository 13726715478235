@import '../../../node_modules/tachyons-sass/scss/hovers';

// Converted Variables


// Custom Media Query Variables


/*

  HOVER EFFECTS
  Docs: http://tachyons.io/docs/themes/hovers/

*/


.cursor-nesw-resize {
  cursor: nesw-resize;
}