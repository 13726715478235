@import '../../../node_modules/tachyons-sass/scss/clears';

// Converted Variables


// Custom Media Query Variables


/*

   CLEARFIX
   http://tachyons.io/docs/layout/clearfix/

*/

// Flexbox + clearfix fix
// Source: https://danisadesigner.com/blog/flexbox-clear-fix-pseudo-elements/
.cf:before,
.cf:after {
  flex-basis: 0;
  order: 1;
}