$spinner-border-width: .125em; // ~2px @ 16px;

@keyframes loader-spinner {
  0% {
    opacity: 0.8;
    transform: scale(1);
  }
  100% {
    opacity: 0.4;
    transform: scale(.97);
  }
}

.loader {
  &,
  &:before {
    position: absolute;
    margin-top: -($line-height-copy * .5em);
    margin-left: -($line-height-copy * .5em);
    top: 50%;
    left: 50%;
    text-align: center;
    width: $line-height-copy * 1em;
    height: $line-height-copy * 1em;
  }

  &:before {
    content: '';
    box-sizing: border-box;
    border: $spinner-border-width solid;
    animation: loader-spinner .5s ease-in infinite alternate;
  }
  &.loader-thin:before {
    border-width: $spinner-border-width/2;
  }
}
