@import '../../../node_modules/tachyons-sass/scss/overflow';

// Converted Variables


// Custom Media Query Variables


/*

    OVERFLOW

    Media Query Extensions:
      -ns = not-small
      -m  = medium
      -l  = large

 */

// iOS scrolling behaviour

[class*="overflow"][class*="hidden"] { -webkit-overflow-scrolling: auto; }

[class*="overflow"][class*="scroll"],
[class*="overflow"][class*="auto"] { -webkit-overflow-scrolling: touch; }
