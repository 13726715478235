@import '../../../node_modules/tachyons-sass/scss/heights';
// Converted Variables


// Custom Media Query Variables

/*

   HEIGHTS

*/

.h-0 {
  height: 0;
}

@media #{$breakpoint-not-small} {
  .h-0-ns { height: 0; }
}

@media #{$breakpoint-medium} {
  .h-0-m { height: 0; }
}

@media #{$breakpoint-large} {
  .h-0-l { height: 0; }
}
