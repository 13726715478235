@import '../../../node_modules/tachyons-sass/scss/normalize';

html {
  line-height: 1.5;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

// Extra resets

p,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
}
