@import '../../../node_modules/tachyons-sass/scss/images';

// Converted Variables


// Custom Media Query Variables

// TODO: figure out how to get video size in Hugo
// Meanwhile... set the max known width
.video {
  max-width: 1200px;
  video {
    // object-fit: cover; // only use with overflow-hidden on parent (behaves like like bg-cover)
  }
}
// Temporary hard limiting to portrait image widths
// to iPhone 6/7/8 width
// TODO: find a better solution for this
.figure-ar-9x16 {
  max-width: (375/strip-units($font-size-base)) * 1rem;
}

// TODO: move to aspect-ratio file
.aspect-ratio--235x1 { padding-bottom: percentage(1/2.35); }
@media #{$breakpoint-not-small}{
  .aspect-ratio--235x1-ns { padding-bottom: percentage(1/2.35); }
}
@media #{$breakpoint-medium}{
  .aspect-ratio--235x1-m { padding-bottom: percentage(1/2.35); }
}
@media #{$breakpoint-large}{
  .aspect-ratio--235x1-l { padding-bottom: percentage(1/2.35); }
}

// SVG spinner background
// TODO: consider using on .lazyloading with blurred image placeholders
figure,
.video {
  background-image: url('#{$img-path}icons/loader.svg');
  background-size: $height-2;
  video,
  img {
    transition: opacity .4s ease-out;
    @extend .o-0;
  }
  @extend .bg-center;
  &.loaded {
    background-image: none;
    video,
    img { @extend .o-100; }
  }
}

.lazy {
  visibility: hidden;
}

.lazyloaded {
  visibility: visible;
}

// Simple image slideshow
.gallery-item {
  $speed: 600ms;
  visibility: hidden;
  transition: opacity $speed ease, visibility 0s $speed;
  & + & {
    margin-left: -100%;
  }
  &.active {
    visibility: visible;
    transition: opacity $speed ease, visibility 0s;
  }
}

// Device frames
.media-frame {
  &.media-frame-iphone-8 {
    padding-top: 9.6%;
    padding-bottom: 10.5%;
    padding-left: 21.8%;
    padding-right: 20.6%;
    .media-frame-device {
      background-image: url('#{$img-path}devices/iphone-8-minimal-white-shadow@2x.png');
      top: -19.6%;
      bottom: -20.6%;
      right: -31.8%;
      left: -33.7%;
    }
  }
}


// Video play/pause animations

@keyframes animatedMediaBtn {
  0% {
    opacity: 1;
    transform: scale(1);
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: scale(1.5);
    visibility: hidden;
  }
}

.animated-media {
  $size: 4rem;
  &.paused:after,
  &.played:after {
    animation: animatedMediaBtn .5s;
    background-color: $black-80;
    background-repeat: no-repeat;
    border-radius: 50%;
    content: '';
    height: $size;
    left: 50%;
    margin-left: -($size/2);
    margin-top: -($size/2);
    opacity: 0;
    position: absolute;
    top: 50%;
    visibility: hidden;
    width: $size;
    z-index: 100;
  }
  &.paused:after {
    background-image: url('#{$img-path}icons/pause.svg');
    background-position: ($size * .375) ($size * .361);
  }
  &.played:after {
    background-image: url('#{$img-path}icons/play.svg');
    background-position: ($size * .375) ($size * (1/3));
  }
}


// Media Alignment

.cms-block-image {
  $measure-rel: $measure * strip-units($font-size-4);
  $wm: $measure * .5;
  $wl: $measure * .75;
  $wm-rel: $measure-rel * .5;
  $wl-rel: $measure-rel * .75;
  $bp-m: ($measure-rel + $wm-rel);
  $bp-l: ($measure-rel + $wl-rel);

  &.cms-block-image-right,
  &.cms-block-image-left {
    @media screen and (max-width: (30em - $px)) {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      float: none;
    }
    @media #{$breakpoint-not-small} and (max-width: 60em - $px) {
      width: 50%;
    }
  }
  @media screen and (min-width: #{$bp-m}) {
    &.cms-block-image-left {
      margin-left: -($wm/2);
    }
    &.cms-block-image-right {
      margin-right: -($wm/2);
    }
  }
  @media #{$breakpoint-large} {
    &.cms-block-image-left {
      margin-left: -(60em - $measure-rel)/2 * (1/strip-units($font-size-4));
    }
    &.cms-block-image-right {
      margin-right: -(60em - $measure-rel)/2 * (1/strip-units($font-size-4));
    }
  }
  @media screen and (min-width: #{$bp-l}) {
    &.cms-block-image-left {
      margin-left: -($wl/2);
    }
    &.cms-block-image-right {
      margin-right: -($wl/2);
    }
  }
}
