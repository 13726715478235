@import '../../../node_modules/tachyons-sass/scss/forms';

// Converted Variables


// Custom Media Query Variables


/*

   FORMS

*/

.input {
  transition: border-color .2s ease;
  &:focus {
    border-bottom-color: $primary;
  }
}

// Inputs followed by <label>
// Acts as label and input placeholder
.input-with-label {
  &::placeholder {
    opacity: 1;
    color: transparent;
  }
  + label {
    transform: translate(0,73%) scale(1/strip-units($font-size-7));
  }
  &.has-value + label,
  &:focus + label {
    transform: translate(0,0) scale(1);
  }
  + label {
    transform-origin: bottom left;
    transition: transform .2s ease-out;
  }
}
