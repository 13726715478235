@import '../../../node_modules/tachyons-sass/scss/letter-spacing';

// Converted Variables


// Custom Media Query Variables


/*

  LETTER SPACING
  Docs: http://tachyons.io/docs/typography/tracking/

  Media Query Extensions:
    -ns = not-small
    -m  = medium
    -l  = large

*/

.tracked-wide { letter-spacing: $letter-spacing-3; margin-right: -$letter-spacing-3; }
.tracked-extreme { letter-spacing: $letter-spacing-4; margin-right: -$letter-spacing-4; }

@media #{$breakpoint-not-small} {
  .tracked-wide-ns { letter-spacing: $letter-spacing-3; margin-right: -$letter-spacing-3; }
  .tracked-extreme-ns { letter-spacing: $letter-spacing-4; margin-right: -$letter-spacing-4; }
}

@media #{$breakpoint-medium} {
  .tracked-wide-m { letter-spacing: $letter-spacing-3; margin-right: -$letter-spacing-3; }
  .tracked-extreme-m { letter-spacing: $letter-spacing-4; margin-right: -$letter-spacing-4; }
}

@media #{$breakpoint-large} {
  .tracked-wide-l { letter-spacing: $letter-spacing-3; margin-right: -$letter-spacing-3; }
  .tracked-extreme-l { letter-spacing: $letter-spacing-4; margin-right: -$letter-spacing-4; }
}
