@import '../../../node_modules/tachyons-sass/scss/z-index';

// Converted Variables

// Custom Media Query Variables


// Large z-index ranges
.z-100 { z-index: 100; } // Aspect ratio objects
.z-200 { z-index: 200; }
.z-300 { z-index: 300; }
.z-400 { z-index: 400; }
.z-500 { z-index: 500; }

// Modal backdrop
.z-1000 { z-index: 1000; }
// Modal
.z-1001 { z-index: 1001; }