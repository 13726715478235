@import '../../../node_modules/tachyons-sass/scss/font-family';

// Converted Variables


// Custom Media Query Variables

.font-inherit {
  font-family: inherit;
}

// Font files

@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: normal;
  src: local('Metropolis Regular'), local('Metropolis-Regular'),
       url('#{$font-path}Metropolis-Regular.woff2') format('woff2'),
       url('#{$font-path}Metropolis-Regular.woff') format('woff');
}
// @font-face {
//   font-family: 'Metropolis';
//   font-style: normal;
//   font-weight: 500;
//   src: local('Metropolis Medium'), local('Metropolis-Medium'),
//        url('#{$font-path}Metropolis-Medium.woff2') format('woff2'),
//        url('#{$font-path}Metropolis-Medium.woff') format('woff');
// }
// Doesn't work on Netlify!!!!???
// @font-face {
//   font-family: 'Metropolis';
//   font-style: normal;
//   font-weight: 600;
//   src: local('Metropolis Semi Bold'), local('Metropolis-SemiBold'),
//        url('#{$font-path}Metropolis-SemiBold.woff2') format('woff2'),
//        url('#{$font-path}Metropolis-SemiBold.woff') format('woff');
// }
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-weight: 700;
  src: local('Metropolis Bold'), local('Metropolis-Bold'),
       url('#{$font-path}Metropolis-Bold.woff2') format('woff2'),
       url('#{$font-path}Metropolis-Bold.woff') format('woff');
}

//  Generated by https://google-webfonts-helper.herokuapp.com/
/* amiri-regular - latin */
@font-face {
  font-family: 'Amiri';
  font-style: normal;
  font-weight: 400;
  src: local('Amiri Regular'), local('Amiri-Regular'),
       url('#{$font-path}amiri-v11-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$font-path}amiri-v11-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* amiri-italic - latin */
@font-face {
  font-family: 'Amiri';
  font-style: italic;
  font-weight: 400;
  src: local('Amiri Italic'), local('Amiri-Italic'),
       url('#{$font-path}amiri-v11-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$font-path}amiri-v11-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
